import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { LoadingStates } from '../../../shared/components/loading-container/loading-container.component';

import { FilterStatePricePredictionsService } from './filter-state-price-predictions.service';
import { GlobalLoadingService } from '../../../shared/services/global-loading.service';
import { RequestModelPaged } from '../../../apis/msb/models/request-model-paged';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { DynamicTableComponent } from '../../../shared/components/dynamic-table/dynamic-table.component';

@Component({
  selector: 'app-manage-price-predictions',
  templateUrl: './price-predictions.page.html',
  styleUrls: ['./price-predictions.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricePredictionsComponent implements OnInit {
  @ViewChild(DynamicTableComponent)
  _dynamicTableComponent: DynamicTableComponent;
  readonly tableLoading$ = new BehaviorSubject<LoadingStates>(
    LoadingStates.LOADING
  );

  readonly filter$ = this.filterStateService.filterState.getFilterData$.pipe(
    map((fltr) => {
      return {
        ...fltr
      };
    }),
    tap((fltr) => {
      setTimeout(() => {
        if (this._dynamicTableComponent) {
          this._dynamicTableComponent.config.pageLength = fltr.pageLength;
          this._dynamicTableComponent.page.limit = fltr.pageLength;
          this._dynamicTableComponent.page.search = fltr.search;
          this._dynamicTableComponent.reloadTable();
        }
        this.globalLoadingService.release();
      }, 1000);
    }),
    debounceTime(100),
    shareReplay()
  );

  readonly hasCruiseIdSelected$: Observable<boolean> = this.filter$.pipe(
    map((fltr) => !!fltr?.cruiseId?.length),
    shareReplay(1)
  );

  readonly selectedTrip$ = this.filter$.pipe(
    map((fltr) => !!fltr?.cruiseId?.length),

    shareReplay(1)
  );

  readonly labelDict$ = this.filterStateService.predictionTypeOpts$.pipe(
    map((arr) =>
      {
        const dict = {};
        arr.forEach((item) => {
          dict[item.id] = item.label;
        });
        return dict;
      }
    ),
    shareReplay(1)
  );

  constructor(
    readonly filterStateService: FilterStatePricePredictionsService,
    readonly globalLoadingService: GlobalLoadingService,
    readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.globalLoadingService.release();
  }

  fetchData(): (request: RequestModelPaged) => Observable<any> {
    return (request) =>
      this.filter$.pipe(
        tap((fltr) => {
          request.customFilter = [];
          if (fltr?.ship) {
            const customFilterKey = 'ship';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.ship + '-'
              });
            }
          }
          if (fltr?.cruiseId) {
            const customFilterKey = 'cruiseId';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.cruiseId
              });
            }
          }
          if (fltr?.predictionType1) {
            const customFilterKey = 'predictionType1';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.predictionType1
              });
            }
          }
          if (fltr?.predictionType2) {
            const customFilterKey = 'predictionType2';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.predictionType2
              });
            }
          }
          if (fltr?.predictionType3) {
            const customFilterKey = 'predictionType3';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.predictionType3
              });
            }
          }
          if (fltr?.priceModel) {
            const customFilterKey = 'priceModel';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.priceModel
              });
            }
          }
          if (fltr?.cabinType) {
            const customFilterKey = 'cabinType';
            if (!request.customFilter.some((x) => x.key === customFilterKey)) {
              request.customFilter.push({
                key: customFilterKey,
                value: fltr?.cabinType
              });
            }
          }
        }),
        switchMap(() =>
          this.adminService
            .streamPricePredictions({
              body: request
            })
            .pipe(
              switchMap((response) => {
                return combineLatest([
                  this.filterStateService.filterState.filterStates.cruiseId
                    .filterSettings$,
                  this.filterStateService.filterState.filterStates.ship
                    .filterSettings$
                ]).pipe(
                  filter(
                    ([cruiseIdOpts, shipOpts]) =>
                      !!cruiseIdOpts?.availableItems?.length &&
                      !!shipOpts?.availableItems?.length
                  ),
                  take(1),
                  map(([cruiseIdOpts, shipOpts]) => {
                    if (!!response?.data?.length) {
                      response.data.forEach((row) => {
                        row['route'] =
                          cruiseIdOpts?.availableItems?.find(
                            (c) => c?.id === row?.cruiseId
                          )?.label ?? '';
                        row['ship'] =
                          shipOpts?.availableItems?.find(
                            (c) =>
                              c?.id === row?.cruiseId?.split('-')?.[0] ?? ''
                          )?.label ?? '';
                      });
                    }
                    return response;
                  })
                );
              })
            )
        )
      );
  }

  clickRow() {
    this.tableLoading$.next(LoadingStates.LOADING);
    setTimeout(() => {
      this.tableLoading$.next(LoadingStates.LOADED);
    }, 500);
  }
}
