<div
  style="padding: 10px"
  *ngIf="{ hasCruiseIdSelected: hasCruiseIdSelected$ | async, dict: labelDict$ | async } as resolvedData"
>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header> Filters </mat-expansion-panel-header>
    <app-deeplink-filter
      header-filter
      [filterStateService]="filterStateService"
      [withFunctionButtons]="true"
      [withBadges]="true"
    >
    </app-deeplink-filter>
  </mat-expansion-panel>
  <!--Table content-->
  <div *ngIf="filter$ | async as filterData">
    <div class="row" *ngIf="filterData?.pageLength">
      <div class="col-12">
        <ng-template #cruiseIdTemplateRef let-row="row">
          <span [title]="row | json"> {{row.cruiseId}} </span>
        </ng-template>

        <ng-template #prediction30DaysTemplateRef let-row="row">
          <div
            style="
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            [title]="(row.confidence1 * 100) + '% Wahrscheinlichkeit'"
          >
            {{resolvedData.dict[row.priceCruise1]}}
          </div>
        </ng-template>

        <ng-template #prediction60DaysTemplateRef let-row="row">
          <div
            style="
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            [title]="(row.confidence2 * 100) + '% Wahrscheinlichkeit'"
          >
            {{resolvedData.dict[row.priceCruise2]}}
          </div>
        </ng-template>

        <ng-template #prediction90DaysTemplateRef let-row="row">
          <div
            style="
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            [title]="(row.confidence3 * 100) + '% Wahrscheinlichkeit'"
          >
            {{resolvedData.dict[row.priceCruise3]}}
          </div>
        </ng-template>

        <ng-template #cellDateAddedRef let-row="row">
          {{row?.dateAdded | date : 'dd.MM.yy'}} @ {{row?.dateAdded | date
          :'shortTime'}}
        </ng-template>
        <ng-template #cellRoleRef let-row="row">
          <span
            class="account_role"
            *ngFor="let role of row?.roles"
            [style.color]="role === 'CUSTOMER' ? 'green' : '#000' "
          >
            {{role}}
          </span>
        </ng-template>

        <ng-container
          *ngIf="{
            defaultOrder: 'id',
            defaultOrderDirection: 'desc',
            pageLength: filterData.pageLength,
            columns: [
              { prop: 'cruiseId', name: 'CruiseId', cellTemplate: cruiseIdTemplateRef },
              { prop: 'ship', name: 'Schiff', sortable: false },
              { prop: 'route', name: 'Abreisetermin', sortable: false },
              { prop: 'cabin', name: 'Kabinencode' },
              { prop: 'priceModel', name: 'Tarif' },
              { prop: 'priceCruise1', name: 'Prognose 30 Tage', cellTemplate: prediction30DaysTemplateRef },
              { prop: 'priceCruise2', name: 'Prognose 60 Tage', cellTemplate: prediction60DaysTemplateRef },
              { prop: 'priceCruise3', name: 'Prognose 90 Tage', cellTemplate: prediction90DaysTemplateRef },
              { prop: 'dateAdded', name: 'Prognose erstellt am', cellTemplate: cellDateAddedRef }
            ],
            fetchData: fetchData()
          } as tableConfig"
        >
          <dynamic-table
            [config]="tableConfig"
            [hideFilters]="true"
            (rowClick)="clickRow()"
            (tableDraw)="globalLoadingService.release()"
          >
          </dynamic-table>
        </ng-container>
      </div>
    </div>
  </div>
</div>
