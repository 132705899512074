import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';

import { PATHS } from '../../../shared/enums/paths.enum';
import { ROUTE } from '../../../shared/enums/route.enum';
import {
  INavigationStateService,
  InitializeNavigationStateHandler,
  NavigationStateHandler,
  UltraDynamicFilterConfig,
  UltraDynamicFilterStateMap
} from '../../../shared/navigation/navigation-state-handler.class';
import * as moment from 'moment';
import { AdminService } from '../../../apis/msb/services/admin.service';
import { map, shareReplay } from 'rxjs/operators';
import { orderBy, uniq } from 'lodash';
import {DashboardService} from '../../../apis/msb/services/dashboard.service';

export interface PricePredictionsFilterState {
  pageLength?: number;
  search?: string;
  ship?: string;
  cruiseId?: string;
  predictionType1?: string;
  predictionType2?: string;
  predictionType3?: string;
  priceModel?: string;
  cabinType?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FilterStatePricePredictionsService
  implements INavigationStateService<PricePredictionsFilterState>
{
  private readonly _base: NavigationStateHandler;

  readonly _dynamicFilterState: UltraDynamicFilterStateMap<PricePredictionsFilterState>;

  public get filterState() {
    return this._dynamicFilterState[ROUTE.MANAGE_PRICE_PREDICTIONS];
  }

  readonly allTimetables$ = this._adminService
    .streamTimetables({
      body: {
        start: 0,
        sortDirection: 'asc',
        sortColumn: 'startDate',
        searchValue: '',
        length: 1000000,
        draw: 3,
        customFilter: []
      }
    })
    .pipe(shareReplay(1));

  readonly predictionTypeOpts$ = of([
    { id: '0', label: 'Preise stabil (-2% +2%)' },
    { id: '1', label: 'Preise steigen (5%-10%)' },
    { id: '2', label: 'Preise steigen stark (>10%)' },
    { id: '3', label: 'Preise fallen (-5% - -10%)' },
    { id: '4', label: 'Preise fallen stark (>-10%)' },
    { id: '5', label: 'Preise steigen (2%-5%)' },
    { id: '6', label: 'Preise fallen (-2% - -5%)' },

  ]).pipe(shareReplay(1));

  readonly config: UltraDynamicFilterConfig<any> = {
    route: ROUTE.MANAGE_PRICE_PREDICTIONS,
    transformFilters: (fltr) => this._parseFilter(fltr),
    filters: [
      {
        type: 'DROPDOWN',
        matrixParameter: 'pageLength',
        label: 'Einträge pro Seite',
        iconName: '',
        required: true,
        options$: of([
          { id: '5', label: '5 pro Seite' },
          { id: '10', label: '10 pro Seite' },
          { id: '50', label: '50 pro Seite' },
          { id: '100', label: '100 pro Seite' },
          { id: '1000', label: '1000 pro Seite' }
        ]),
        defaultValue: '50',
        emptyOptionValue: null
      },
      // {
      //   type: 'INPUT_TEXT',
      //   matrixParameter: 'search',
      //   controlOptions: {
      //     debounceTime: 500
      //   },
      //   label: 'Suchbegriff',
      //   iconName: 'search',
      //   emptyOptionValue: null
      // },
      {
        type: 'DROPDOWN',
        matrixParameter: 'ship',
        label: 'Schiff',
        iconName: 'directions_boat',
        required: true,
        options$: this.allTimetables$.pipe(
          map((response) => {
            if (response?.data?.length) {
              const uniqueShipIds = uniq(
                response.data.map((row) => row.shipId)
              );
              const shipOptions = uniqueShipIds.map((row) => {
                return {
                  id: row.toString(),
                  label: response.data.find((x) => x.shipId === row)?.route
                    ?.ship?.shipname
                };
              });
              return orderBy(shipOptions, 'label');
            } else {
              return [];
            }
          })
        ),
        defaultValue: null
      },
      {
        type: 'DROPDOWN',
        matrixParameter: 'cruiseId',
        label: 'Abreisetermin',
        iconName: 'date_range',
        required: true,
        optionsFiltered$: (fltr) => {
          return this.allTimetables$.pipe(
            map((response) => {
              if (response?.data?.length) {
                const opts = response.data
                  .filter((row) => {
                    if (fltr.ship) {
                      return row.shipId.toString() === fltr.ship;
                    } else {
                      return true;
                    }
                  })
                  .map((row) => {
                    return {
                      id: row.cruiseId,
                      label: `${row?.startDate} | ${row?.route?.name}`
                    };
                  });
                return orderBy(opts, 'label');
              } else {
                return [];
              }
            })
          );
        },
        defaultValue: null
      },
      {
        type: 'DROPDOWN',
        matrixParameter: 'priceModel',
        label: 'Tarif',
        iconName: '',
        required: true,
        optionsFiltered$: (fltr) => {
          return this._adminService.getPricePredictionPriceModels().pipe(
            map(res => {
              return res.map(x => ({
                id: x,
                label: x
              }))
            }),
            shareReplay(1)
          )
        },
        defaultValue: null
      },
      // {
      //   type: 'DROPDOWN',
      //   matrixParameter: 'cabinType',
      //   label: 'Kabinenkategorie',
      //   iconName: '',
      //   required: true,
      //   options$: of([
      //     { id: '1', label: 'Innen' },
      //     { id: '2', label: 'Außen' },
      //     { id: '3', label: 'Balkon' },
      //     { id: '4', label: 'Suite' }
      //   ]),
      //   defaultValue: null,
      //   emptyOptionValue: null
      // },
      {
        type: 'DROPDOWN',
        matrixParameter: 'predictionType1',
        label: 'Prognose 30 Tage',
        iconName: '',
        required: true,
        options$: this.predictionTypeOpts$,
        defaultValue: null,
        emptyOptionValue: null
      },
      {
        type: 'DROPDOWN',
        matrixParameter: 'predictionType2',
        label: 'Prognose 60 Tage',
        iconName: '',
        required: true,
        options$: this.predictionTypeOpts$,
        defaultValue: null,
        emptyOptionValue: null
      },
      {
        type: 'DROPDOWN',
        matrixParameter: 'predictionType3',
        label: 'Prognose 90 Tage',
        iconName: '',
        required: true,
        options$: this.predictionTypeOpts$,
        defaultValue: null,
        emptyOptionValue: null
      }
    ]
  };

  constructor(
    protected readonly _router: Router,
    protected readonly _adminService: AdminService
  ) {
    const dynamicNavigation = InitializeNavigationStateHandler<any>(
      PATHS.MSB,
      this.config,
      _router
    );
    this._dynamicFilterState = dynamicNavigation.dynamicFilterState;
    this._base = dynamicNavigation.navigationStateHandler;
  }

  initRoutingState(routeSnapShot: ActivatedRouteSnapshot): void {
    this._base.initRoutingState(routeSnapShot);
  }

  private _parseFilter(fltr): PricePredictionsFilterState {
    const parsedFilter: PricePredictionsFilterState = {};

    if (fltr?.pageLength) {
      parsedFilter.pageLength = parseInt(fltr.pageLength, 10);
    }

    if (fltr?.search) {
      parsedFilter.search = fltr.search;
    }

    if (fltr?.ship) {
      parsedFilter.ship = `${fltr.ship}`;
    }

    if (fltr?.cruiseId) {
      parsedFilter.cruiseId = `${fltr.cruiseId}`;
    }

    if (fltr?.predictionType1) {
      parsedFilter.predictionType1 = `${fltr.predictionType1}`;
    }

    if (fltr?.predictionType2) {
      parsedFilter.predictionType2 = `${fltr.predictionType2}`;
    }

    if (fltr?.predictionType3) {
      parsedFilter.predictionType3 = `${fltr.predictionType3}`;
    }

    if (fltr?.priceModel) {
      parsedFilter.priceModel = `${fltr.priceModel}`;
    }

    if (fltr?.cabinType) {
      parsedFilter.cabinType = `${fltr.cabinType}`;
    }

    return parsedFilter;
  }
}
